.container {
  background-color: #000;
}

#myProgress {
  width: calc(100% - 40px);
  height: 40px;
  box-sizing: border-box;
  border: 4px solid #000;
  border-radius: 5px;
  position: absolute;
  left: 20px;
  overflow: hidden;
}

#myBarBackground {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #ed8b00;
  position: absolute;
}

#myBar {
  width: 0%;
  height: 100%;
  background-color: green;
  position: absolute;
  overflow: hidden;
}

/*# sourceMappingURL=index.ff42feda.css.map */
