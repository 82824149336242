// @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Acme&family=Alfa+Slab+One&family=Amatic+SC:wght@700&family=Architects+Daughter&family=Barrio&family=Black+Ops+One&family=Bonbon&family=Bubblegum+Sans&family=Bungee&family=Bungee+Shade&family=Flavors&family=Freckle+Face&family=Fredoka+One&family=Henny+Penny&family=Indie+Flower&family=Lobster&family=Luckiest+Guy&family=Pacifico&family=Permanent+Marker&family=Press+Start+2P&family=Rammetto+One&family=RocknRoll+One&family=Special+Elite&family=UnifrakturMaguntia&family=Voltaire&family=Wendy+One&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Blaka&family=Boogaloo&family=Bowlby+One+SC&family=Calligraffitti&family=Chewy&family=Dekko&family=Erica+One&family=Fredericka+the+Great&family=Fredoka+One&family=Gochi+Hand&family=Love+Ya+Like+A+Sister&family=Marck+Script&family=Miltonian+Tattoo&family=Pangolin&family=Patrick+Hand+SC&family=Ribeye&family=Rock+Salt&family=Sedgwick+Ave&family=Ultra&family=UnifrakturCook&family=Walter+Turncoat&display=swap');

.container {
	background-color: black;
}
#myProgress {
	position: absolute;
	width: calc(100% - 40px);
	left: 20px;
	height: 40px;
	border: 4px solid black;
	border-radius: 5px;
	box-sizing: border-box;
	overflow: hidden;
}

#myBarBackground {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #ed8b00;
	box-sizing: border-box;
}

#myBar {
	position: absolute;
	width: 0%;
	height: 100%;
	// height: 20px;
	background-color: green;
	// transition: width .5s linear;
	overflow: hidden;
}
